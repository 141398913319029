@tailwind base;
@tailwind components;
@tailwind utilities;




@layer components {

  .anchor-cont {
    @apply flex items-center p-2 text-black rounded-lg hover:text-white hover:bg-violet-600;
  }

  .anchor-txt {
    @apply flex-1 ml-3 font-normal whitespace-nowrap antialiased;
  }


}

